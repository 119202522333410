<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="策略名称">
            <el-input v-model="searchForm.strategyName" placeholder="请输入 策略名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="事件级别">
            <el-select clearable v-model="searchForm.eventLevel" placeholder="请选择" size="large">
              <el-option v-for="item in levelList" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="方式">
            <el-select clearable v-model="searchForm.way" placeholder="请选择" size="large">
              <el-option v-for="item in wayList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button class="searchBtn" @click="dialogFormVisible = true;title = '新增'">新增</el-button>
      </template>
    </Searchbar>
    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>

    <!--  新增编辑  -->
    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="140px"
          ref="dialogForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="策略名称" prop="strategyName" :rules="[{ required: true, message: '策略名称 不能为空' }]">
              <el-input placeholder="请输入 策略名称" clearable v-model="form.strategyName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

<!--          <el-col :span="12">-->
<!--            <el-form-item label="事件级别名称" prop="eventLevelName" :rules="[{ required: true, message: '事件级别名称 不能为空' }]">-->
<!--              <el-input placeholder="请输入 事件级别名称" clearable v-model="form.eventLevelName" autocomplete="off"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span="12">
            <el-form-item label="事件级别" prop="eventLevel" :rules="[{ required: true, message: '事件级别 不能为空' }]">
              <el-select  clearable v-model="form.eventLevel" placeholder="请选择" size="large">
                <el-option v-for="item in levelList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="事件类型" prop="eventType">
              <el-select clearable filterable v-model="form.eventType" placeholder="请选择" size="large">
                <el-option v-for="item in typeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

<!--          <el-col :span="12">-->
<!--            <el-form-item label="事件类型名称" prop="eventTypeName">-->
<!--              <el-input placeholder="请输入 事件类型名称"  clearable v-model="form.eventTypeName" autocomplete="off"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span="12">
            <el-form-item label="方式" prop="way" :rules="[{ required: true, message: '方式 不能为空' }]">
              <el-select clearable v-model="form.way" placeholder="请选择" size="large">
                <el-option v-for="item in wayList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSend">提交</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import {reactive, onMounted, toRefs, ref, nextTick} from 'vue';
import { getData, codeInfo, dataSave, getedit, dataDel } from "@/api/devops/strategy";
import Searchbar from '@/components/Searchbar';
import {ElMessage} from "element-plus";
export default {
  components:{
    Searchbar
  },
  setup(){
    let idx = ref('');
    let title = ref('');
    let dialogForm = ref(null);
    let dialogFormVisible = ref(false);
    let state = reactive({
      wayList:[
        {
          value: 1,
          label: '自动放行',
        },
        {
          value: 2,
          label: '拦截审核',
        },
      ],
      typeList:[],
      levelList:[],
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "strategyName",
          label: "策略名称",
          render: (row) => <div>{row.strategyName}</div>,
        },
        {
          prop: "eventLevel",
          label: "事件级别",
          render: (row) => {
            let sc = new Map([
              [1,"红(特别重大)"],
              [2,"橙(重大)"],
              [3,"黄(较大)"],
              [4,"蓝(一般)"],
              [0,"缺省"]
            ])
            return <div>{sc.get(row.eventLevel)}</div>
          },
        },
        // {
        //   prop: "eventType",
        //   label: "事件类型",
        //   render: (row) => <div>{state.typeList.filter(item=>item.code == row.eventType)[0].name}</div>,
        // },
        {
          prop: "way",
          label: "状态",
          render: (row) => <div>{ row.way == 1 ? "自动放行" : "拦截审核" }</div>,
        },
        {
          prop: "createTime",
          label: "录入时间",
          render: (row) => <div>{row.createTime}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={()=>dataEdit(row)}>
                  编辑
                </el-button>
                <el-button type="text" icon="Delete" onClick={()=>del(row)}>
                  删除
                </el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    })

    onMounted(()=>{
      codeInfo({type:2}).then(res=>state.typeList = res.data.data);
      codeInfo({type:3}).then(res=>{
        res.data.data.forEach(item=>{
          item.code = Number(item.code);
        })
        state.levelList = res.data.data;
      });

      onLoad();
    })

    function onLoad(form = {}){
      getData({ limit:state.page.limit, page:state.page.current, ...form }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function del(row){
      dataDel({ id:row.id }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          state.page.current = 1;
          onLoad();
        }
      })
    }

    function dataEdit(row){
      state.form = row;
      if(state.form.eventType == "all") state.form.eventType = "";
      dialogFormVisible.value = true;
      title.value = '编辑';
      idx.value = row.id
    }

    function onSend(){
      dialogForm.value.validate((valid, fields) => {
        if (valid) {
          if(!state.form.eventType){
            state.form.eventType = 'all';
            state.form.eventTypeName = '所有';
          }else{
            state.form.eventTypeName = state.typeList.filter(item=>item.code == state.form.eventType)[0].name;
          }

          state.form.eventLevelName = state.levelList.filter(item=>item.code == state.form.eventLevel)[0].name;

          if(title.value == '新增'){
            dataSave(state.form).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                state.page.current = 1;
                onLoad();
                dialogForm.value.resetFields();
                state.form = {};
                dialogFormVisible.value = false;
              }
            })
          }else{
            getedit({ ...state.form, id:idx.value }).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                state.page.current = 1;
                onLoad();
                dialogForm.value.resetFields();
                state.form = {};
                dialogFormVisible.value = false;
              }
            })
          }
        } else {
          console.log('error submit!', fields)
        }
      })
    }

    function onSearch(){
      state.page.current = 1;
      onLoad(state.searchForm);
    }

    function handleClose(done){
      nextTick(()=>{
        dialogForm.value.resetFields();
        state.form = {}
      })
      done()
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    function resetForm() {
      state.searchForm = {};
      onLoad(state.searchForm);
    }

    return{
      handleSizeChange,
      handleCurrentChange,
      resetForm,
      idx,
      title,
      dialogForm,
      dialogFormVisible,
      onSearch,
      onSend,
      handleClose,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>

</style>